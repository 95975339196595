export default {
    importInterfacePanel: () =>
        import(/* webpackChunkName: "interfacePanel" */
            './components/configs/Interface/InterfacePanel'),
    importPropertyPanel: () =>
        import(/* webpackChunkName: "propertyPanel" */
            './components/configs/PropertyPanel'),
    importTextTemplatePanel: () =>
        import(/* webpackChunkName: "textTemplatePanel" */
            './components/configs/TextTemplatePanel'),
    importReportPanel: () =>
        import(/* webpackChunkName: "reportPanel" */
            './components/configs/ReportPanel'),
    importItemTypePanel: () =>
        import(/* webpackChunkName: "itemTypePanel" */
            './components/configs/ItemTypePanel'),
    importFileRepositoryPanel: () =>
        import(/* webpackChunkName: "fileRepositoryPanel" */
            './components/configs/FileRepositoryPanel'),
    importLanguagePanel: () =>
        import(/* webpackChunkName: "languagePanel" */
            './components/configs/Translations/LanguagePanel'),
    importDataProviderPanel: () =>
        import(/* webpackChunkName: "dataProviderPanel" */
            './components/configs/DataProviders/DataProviderPanel'),
    importPermissionPanel: () =>
        import(/* webpackChunkName: "permissionPanel" */
            './components/configs/PermissionGroups/PermissionPanel'),
    importCategoryPanel: () =>
        import(/* webpackChunkName: "categoryPanel" */
            './components/configs/Category/CategoryPanel'),
    importSystemInfoPanel: () =>
        import(/* webpackChunkName: "systemInfoPanel" */
            './components/configs/SystemInfoPanel'),
    importTactinMainMenu: () =>
        import(/* webpackChunkName: "mainMenuPanel" */
            './components/configs/Interface/MainMenuPanel'),
    importMainTabsPanel: () =>
        import(/* webpackChunkName: "mainTabsPanel" */
            './components/configs/Interface/MainTabsPanel'),
    importCardsPanel: () =>
        import(/* webpackChunkName: "cardsPanel" */
            './components/configs/Interface/CardsPanel'),
    importToolbarPanel: () =>
        import(/* webpackChunkName: "toolbarPanel" */
            './components/configs/Interface/ToolbarPanel'),
    importDefaultsPanel: () =>
        import(/* webpackChunkName: "defaultsPanel" */
            './components/configs/Interface/DefaultsPanel'),

    importConfigTabPanel: () =>
        import(/* webpackChunkName: "configTabPanel" */
            './components/configs/ConfigTabPanel'),

    importTestProperty: () =>
        import(/* webpackChunkName: "testProperty" */
            './components/propertycontrols/PropertyTester'),
    importTestList: () =>
        import(/* webpackChunkName: "testList" */
            './components/list/GroupListViewer'),
    importTestTree: () =>
        import(/* webpackChunkName: "testTree" */
            './components/list/TreeListViewer'),
    importMainButton: () =>
        import(/* webpackChunkName: "MainButton" */
            './components/basic/MainButton'),
    importLoginWindow: () =>
        import(/* webpackChunkName: "loginWindow" */
            './components/security/LoginWindow'),

}
