import React, { ReactChild, useEffect, useState } from 'react';
import importer from '../../importer';
import TabPanel from '../basic/TabPanel';

export default function ConfigTabPanel() {
    return (<TabPanel>
        { createOption('Interface', 'box41', 1, () =>
            importer.importInterfacePanel().then(({default: InterfacePanel}) => <InterfacePanel/>)) }
        { createOption('Categories', 'box41', 2, () => 
            importer.importCategoryPanel().then(({default: CategoryPanel}) => <CategoryPanel/>)) }
        { createOption('System info', 'box41', 3, () => 
            importer.importSystemInfoPanel().then(({default: SystemInfoPanel}) => <SystemInfoPanel/>)) }
        { createOption('Permissions', 'box41', 4, () => 
            importer.importPermissionPanel().then(({default: PermissionPanel}) => <PermissionPanel/>)) }
        { createOption('Data providers', 'box41', 5, () => 
            importer.importDataProviderPanel().then(({default: DataProviderPanel}) => <DataProviderPanel/>)) }
        { createOption('File repository', 'box41', 6, () => 
            importer.importFileRepositoryPanel().then(({default: FileRepositoryPanel}) => <FileRepositoryPanel/>)) }
        { createOption('Text templates', 'box41', 7, () => 
            importer.importTextTemplatePanel().then(({default: TextTemplatePanel}) => <TextTemplatePanel/>)) }
        { createOption('Properties', 'box41', 8, () => 
            importer.importPropertyPanel().then(({default: PropertyPanel}) => <PropertyPanel/>)) }
        { createOption('Reports', 'box41', 9, () => 
            importer.importReportPanel().then(({default: ReportPanel}) => <ReportPanel/>)) }
        { createOption('Item types', 'box41', 10, () => 
            importer.importItemTypePanel().then(({default: ItemTypePanel}) => <ItemTypePanel/>)) }
        { createOption('Languages', 'box41', 11, () => 
            importer.importLanguagePanel().then(({default: LanguagePanel}) => <LanguagePanel/>)) }
    </TabPanel>);
}

export function DelayedPanel({ panel: panelFunc }: { panel: () => Promise<ReactChild>}) {
    const [panel, setPanel] = useState<ReactChild | undefined>(undefined);

    useEffect(() => {
        panelFunc().then(setPanel).catch(_ => setPanel(undefined));
    }, []);

    return <>{panel}</>;
}

function createOption(name: string, icon: string, index: number, panelFun: () => Promise<ReactChild>) {
    return {
        panelLabel: <><img src={`img/${icon}.png`} /><div>{name}</div></>,
        panelContent: <DelayedPanel key={index} panel={panelFun} />
    }
}

export function ConfigTabPanelUser () {
    return <div className='config-panel'>
        <ConfigTabPanel />
    </div>
}
